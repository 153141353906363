import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, FreeMode } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

// Images
import HomeBanner2 from "../assets/img/banner/homebanner2.jpg";

// import PromoCover1 from "../assets/img/promo1.jpg";
// import PromoCover2 from "../assets/img/promo2.jpg";
// import PromoCover3 from "../assets/img/promo3.jpg";
// import PromoCover4 from "../assets/img/promo4.jpg";
// import PromoCover5 from "../assets/img/promo5.jpg";
import PromoCover6 from "../assets/img/promo6.jpg";

SwiperCore.use([Autoplay, Pagination, Navigation, FreeMode]);

const Promotions = () => {

  return (
    <div>
      {/* <div className="container-fluid">
        <div className="row">
          <div className="col-12 p-0">
            <img className="page-banner" src={HomeBanner2} alt="Homepage Banner"></img>
          </div>
        </div>
      </div> */}
      <div className="container">
        <div className="row my-5">
          <div className="col-12 my-3">
            <h5 className="text-gray mb-0"><span className="heading-box">PROMOTIONS</span></h5>
          </div>
          <div className="col-12 mt-2">
            <Swiper
              loop
              freeMode={true}
              grabCursor={true}
              slidesPerView={2.5}
              spaceBetween={10}
              speed={2000}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                768: {
                  slidesPerView: 3.5,
                  spaceBetween: 15,
                },
                1600: {
                  slidesPerView: 4,
                  spaceBetween: 15,
                },
              }}
            >
              {/* <SwiperSlide>
                <button className="btn p-0" onClick={(e) => {
                }}>
                  <img className="hot-games-img" src={PromoCover1} alt="Promotion Thumnbail"></img>
                </button>
              </SwiperSlide>
              <SwiperSlide><img className="hot-games-img" src={PromoCover2} alt="Promotion Thumnbail"></img></SwiperSlide>
              <SwiperSlide><img className="hot-games-img" src={PromoCover3} alt="Promotion Thumnbail"></img></SwiperSlide>
              <SwiperSlide><img className="hot-games-img" src={PromoCover4} alt="Promotion Thumnbail"></img></SwiperSlide>
              <SwiperSlide><img className="hot-games-img" src={PromoCover5} alt="Promotion Thumnbail"></img></SwiperSlide> */}
              <SwiperSlide><img className="hot-games-img" src={PromoCover6} alt="Promotion Thumnbail"></img></SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );

};

export default Promotions;