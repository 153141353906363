import React, { useEffect, useState } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";
import Moment from 'moment';

// Hooks
import { useAuthContext } from '../hooks/useAuthContext';

const GameHistory = () => {

	const { user } = useAuthContext();

	const [userData, setUserData] = useState();
	const getUserData = async () => {
	  const response = await fetch('https://api.hiclub33.io/api/player/id/'+user.player_id, {
		headers: {
		  'Authorization': `Bearer ${user.token}`
		}
	  });
	  const json = await response.json();
	  console.log(json);
	  if (response.ok) {
		setUserData(json);
	  }
	}

	const [userGameHistory, setUserGameHistory] = useState();
	const getGameHistory = async () => {
	  const response = await fetch('https://api.hiclub33.io/api/game/getGameHistory/', {
		headers: {
		  'Authorization': `Bearer ${user.token}`
		}
	  });
	  const json = await response.json();
	  console.log(json);
	  if (response.ok) {
		setUserGameHistory(json);
	  }
	}
  
	const calculateGameDetails = (game) => {
		const isWin = game.finalAmount > game.betAmount;
		const resultClass = isWin ? 'profit-green' : 'profit-red';
		return {
			...game,
			isWin,
			resultClass,
		};
	};
	
	useEffect(() => {
	  if (user) {
		getUserData();
		getGameHistory();
	  }
	}, [user]);	

	// Pagination
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 20;
	const getPaginatedData = (data) => {
		const startIndex = (currentPage - 1) * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;
		return data.slice(startIndex, endIndex);
	};

	const getTotalPages = (data) => Math.ceil(data.length / itemsPerPage);

	const handlePageChange = (newPage) => {
		if (newPage >= 1 && newPage <= getTotalPages(userGameHistory || [])) {
			setCurrentPage(newPage);
			// Scroll to the top of the table
			document.querySelector('table').scrollIntoView({ behavior: 'smooth' });
		}
	};
	const paginatedData = getPaginatedData(userGameHistory || []);

  	return (
		<div className="container pb-5">
			<div className="row my-3">
				<div className="col-12 mt-3">
					<h4 className="text-gray mb-0">Game History</h4>
				</div>
				{userData ? (
					<>
						{userGameHistory && userGameHistory.length > 0 ? (
							<div className="col-12 mt-3">
								<table className="table table-responsive-md table-hover table-striped table-dark">
									<thead className="thead-dark">
										<tr className="">
											<th className="p-3" scope="col"><p className="mb-0">Game Name</p></th>
											<th className="p-3" scope="col"><p className="mb-0">Bet Amount</p></th>
											<th className="p-3" scope="col"><p className="mb-0">Multiplier</p></th>
											<th className="p-3" scope="col"><p className="mb-0">Final Amount</p></th>
											<th className="p-3" scope="col"><p className="mb-0">Result</p></th>
										</tr>
									</thead>
									<tbody>
										{paginatedData && paginatedData.map((game, index) => {
											const userGH = calculateGameDetails(game); 
											return (
												<tr key={index}>
													<td className="px-3 py-2">
														<p className="text-gray mb-0">{userGH.gameName}</p>
													</td>
													<td className="px-3 py-2">
														<p className="text-gray mb-0">{userGH.betAmount}</p>
													</td>
													<td className="px-3 py-2">
														<p className="mb-0">
															<strong>{userGH.multiplier}</strong>
														</p>
													</td>
													<td className="px-3 py-2">
														<p className="mb-0">{userGH.finalAmount}</p>
													</td>
													<td className="px-3 py-2">
														<p className={`${userGH.resultClass} mb-0`}>{userGH.isWin ? 'Win' : 'Lose'}</p>
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
								{/* Pagination Controls */}
								<div className="pagination-controls mt-3 d-flex justify-content-center align-items-center">
									<button
										className="btn btn-secondary"
										onClick={() => handlePageChange(currentPage - 1)}
										disabled={currentPage === 1}
									>
										Previous
									</button>
									<span className="mx-3 text-gray">
										Page {currentPage} of {getTotalPages(userGameHistory)}
									</span>
									<button
										className="btn btn-secondary"
										onClick={() => handlePageChange(currentPage + 1)}
										disabled={currentPage === getTotalPages(userGameHistory)}
									>
										Next
									</button>
								</div>
							</div>
						):(
							<div className="col-12 mt-3">
								<p className="text-gray mb-2">No record is found for this account.</p>
							</div>
						)}
					</>
				):(
					<></>
				)}
			</div>
		</div>
  	);

};

export default GameHistory;