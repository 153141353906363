import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import MiddleEllipsis from "react-middle-ellipsis";

import { useAuthContext } from '../hooks/useAuthContext';
import { useLaunchGame } from '../hooks/useLaunchGame';

import HCLogo from '../assets/img/logo-square.png';

const GamePage = () => {

	const [searchParams] = useSearchParams();
	const gameCategory = searchParams.get("category");
	const gameProvider = searchParams.get("provider");

	const { user } = useAuthContext();

	const { startGame, isLoading, error, gameLink } = useLaunchGame();

	const [isOpenGame, setIsOpenGame] = useState(false);
	const openGame = () => {
    setIsOpenGame(true);
		// setSelectedGameCode(game._id);
  	};

	const [selectedGameCode, setSelectedGameCode] = useState('');
	const chooseGameLaunch = async () => {
		if (selectedGameCode == '') {
			return;
		}
		await startGame(user.token, selectedGameCode);
		var element = document.getElementById("gameFrame");
		element.scrollIntoView();
    };

	const [gamelist, setGameList] = useState([]);
	const getGameList = async () => {
		const response = await fetch('https://api.hiclub33.io/api/game/getAllGames?category='+gameCategory+'&language=en', {
			headers: {
			'Authorization': `Bearer ${user.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			console.log(json);
			setGameList(json);
		}
	}


	const [userData, setUserData] = useState();
	const getUserData = async () => {
	  const response = await fetch('https://api.hiclub33.io/api/player/id/'+user.player_id, {
		headers: {
		  'Authorization': `Bearer ${user.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		setUserData(json);
	  }
	}
	
	useEffect(() => {
		console.log(gameCategory);
		console.log(gameProvider);
		if (gameProvider) {
			setSelectedProvider(gameProvider);
		}
		if (user && gameCategory) {
			getUserData();
			getGameList();
		}
	}, [user, gameCategory, gameProvider]);

	// Game Provider Filter
	const [selectedProvider, setSelectedProvider] = useState('');
	const uniqueProviders = [...new Set(gamelist.map(game => game.gameProvider))];
	const filteredGames = selectedProvider
		? gamelist.filter(game => game.gameProvider === selectedProvider)
		: gamelist;

	// Pagination
	const [currentPage, setCurrentPage] = useState(1);
	const [gamesPerPage, setGamesPerPage] = useState(30);
	const totalPages = Math.ceil(filteredGames.length / gamesPerPage);
	const startIndex = (currentPage - 1) * gamesPerPage;
	const currentGames = filteredGames.slice(startIndex, startIndex + gamesPerPage);
	const getTruncatedPages = () => {
		const totalShownPages = 5;
		const pages = [];
		if (totalPages <= totalShownPages) {
			for (let i = 1; i <= totalPages; i++) {
			pages.push(i);
			}
		} else {
				const leftEdge = Math.max(1, currentPage - 1); 
				const rightEdge = Math.min(totalPages, currentPage + 1); 
				if (leftEdge > 2) {
					pages.push(1, '...');
				} else {
						for (let i = 1; i < leftEdge; i++) {
							pages.push(i);
						}
					}
				for (let i = leftEdge; i <= rightEdge; i++) {
					pages.push(i);
				}
				if (rightEdge < totalPages - 1) {
					pages.push('...', totalPages);
				} else {
						for (let i = rightEdge + 1; i <= totalPages; i++) {
							pages.push(i);
						}
					}
			}
		return pages;
	};


	return (
		<div className="container">
			{ isOpenGame && (
				<div
					className="modal fade show"
					style={{ display: "block", paddingTop: "100px", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
					aria-modal="true"
					role="dialog"
				>
					<div className="modal-dialog modal-dialog-top" role="document">
						<div className="modal-content bg-black p-3">
							<div className="modal-header" style={{ borderBottom:'1px solid #e9d096'}}>
								<h5 className="modal-title brand-gold"></h5>
								<button
									type="button"
									className="btn-close bg-transparent"
									aria-label="Close"
									onClick={() => setIsOpenGame(false)}
									style={{ border:'none', fontSize:'18px' }}
								>
									<i className="fa-solid fa-circle-xmark brand-gold"></i>
								</button>
							</div>
							<div className="modal-body">
								<p className="text-white text-center">
									Do you wish to proceed with this credit?
									<br/>
									<strong>{userData && parseFloat(Number(userData.cashBalance)+Number(userData.creditBalance)).toFixed(2)}</strong>
								</p>
								<button className="btn btn-block text-start bg-gold" onClick={(e) => {
									chooseGameLaunch();
									setIsOpenGame(false);
								}}>
									Yes
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			<div className="row px-2 my-3">
				{gameLink && gameLink != '' ? (
					<div className="col-12 px-2 mt-3">
						<div id="gameFrame" className="game-frame">
							<iframe className="game-frame-item" src={gameLink} allowFullScreen></iframe>
						</div>
					</div>
				):(
					<></>
				)}

				<div className="col-12 mt-3">
					<h4 className="text-capitalize text-gray mb-0">{gameCategory}</h4>
				</div>

				{/* Game Provider Dropdown */}
				<div className="col-12 d-flex justify-content-end">
					<label className="text-gray font-weight-bold m-0">Game Provider:</label>
					<select
						className="form-select ml-2 bg-transparent gold-border text-white font-weight-bold"
						value={selectedProvider}
						onChange={(e) => setSelectedProvider(e.target.value)}
						style={{outline:'none'}}
					>
						<option value="">All</option>
						{uniqueProviders.map((provider, index) => (
							<option key={index} value={provider}>{provider}</option>
						))}
					</select>
				</div>

				{currentGames && currentGames.length > 0 ? (
					<div className="col-12 mt-3">
						{/* Pagination Controls */}
						<div className="col-12 col-sm-9 col-lg-6 col-xl-4 pagination-container">
							<button
							onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
							disabled={currentPage === 1}
							>
							<i className="fa-solid fa-angle-left"></i>
							</button>

							{getTruncatedPages().map((page, index) => (
							<button
								key={index}
								onClick={() => page !== '...' && setCurrentPage(page)}
								disabled={page === '...'}
								className={currentPage === page ? 'active' : ''}
							>
								{page}
							</button>
							))}

							<button
							onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
							disabled={currentPage === totalPages}
							>
							<i className="fa-solid fa-angle-right"></i>
							</button>
						</div>
						<div className="row">
							{currentGames && currentGames.map((game, index) => (
								<>
									<div className="col-4 col-sm-3 col-lg-2 px-0 text-center" key={index}>
										{/* <button className="btn p-1" onClick={(e) => {
											setSelectedGameCode(game._id);
											chooseGameLaunch();
										}}> */}
										<button className="btn p-1" onClick={(e) => {
											openGame();
											setSelectedGameCode(game._id);
										}}>
											<div className="game-container">
												<img className="w-100" src={game.gameIcon != "" && game.gameIcon != null ? game.gameIcon : HCLogo }></img>
											</div>
										</button>
										
									</div>
								</>
							))}
						</div>

						

					</div>
				):(
					<div className="col-12 mt-3">
						<p className="text-gray">No games available for this category.</p>
					</div>
				)}

	
			</div>
		</div>

	);
};

export default GamePage;