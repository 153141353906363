import React, { useEffect, useState } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";
import Moment from 'moment';

// Hooks
import { useAuthContext } from '../hooks/useAuthContext';

const Wallet = () => {

	const { user } = useAuthContext();

	const [userData, setUserData] = useState();
	const getUserData = async () => {
	  const response = await fetch('https://api.hiclub33.io/api/player/id/'+user.player_id, {
		headers: {
		  'Authorization': `Bearer ${user.token}`
		}
	  });
	  const json = await response.json();
	  console.log(json);
	  if (response.ok) {
		setUserData(json);
	  }
	}

	const [userDepositsData, setUserDepositsData] = useState();
	const getUserDeposits = async () => {
	  const response = await fetch('https://api.hiclub33.io/api/deposit/all/'+user.player_id, {
		headers: {
		  'Authorization': `Bearer ${user.token}`
		}
	  });
	  const json = await response.json();
	  console.log(json);
	  if (response.ok) {
		setUserDepositsData(json);
	  }
	}

	const userWithdrawalsData = []

	useEffect(() => {
	  if (user) {
		getUserData();
		getUserDeposits();
	  }
	}, [user]);

	const [activeTab, setActiveTab] = useState("deposit");
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 20;

	const getPaginatedData = (data = []) => {
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	return data.slice(startIndex, endIndex);
	};

	const getTotalPages = (data = []) => Math.ceil(data.length / itemsPerPage);

	const handlePageChange = (newPage) => {
    const currentData = activeTab === "deposit" ? (userDepositsData || []) : (userWithdrawalsData || []);
    if (newPage >= 1 && newPage <= getTotalPages(currentData)) {
        setCurrentPage(newPage);	
    }
	};
	const currentData = activeTab === "deposit" ? userDepositsData || [] : userWithdrawalsData || [];
	const paginatedData = getPaginatedData(currentData);

  	return (
		<div className="container pb-5">
			<div className="row my-3">
				<div className="col-12 mt-3">
					<h4 className="text-gray mb-0">Wallet</h4>
				</div>
				{userData ? (
					<>
						<div className="col-12 mt-3">
							<div className="page-container">
								<div className="row mt-3 mb-3">
									<div className="col-12 px-2">
										<h5 className="text-gray"><i className="fa-solid fa-wallet mr-2"></i>Summary</h5>
										<div className="page-sub-container">
											<h5 className="light-yellow mb-0">Wallet Balance</h5>
											<p className="currency-lg text-gray mb-0">
												{parseFloat(Number(userData.cashBalance)+Number(userData.creditBalance)).toFixed(2)}
											</p>
										</div>
									</div>
									{/* <div className="col-sm-6 mt-2 mt-sm-3 px-2">
										<div className="page-sub-container">
											<h5 className="light-yellow mb-0">Cash Wallet</h5>
											<p className="currency-sm text-gray mb-0">{parseFloat(userData.cashBalance).toFixed(2)}</p>
										</div>
									</div>
									<div className="col-sm-6 mt-2 mt-sm-3 px-2">
										<div className="page-sub-container">
											<h5 className="light-yellow mb-0">Bonus Wallet</h5>
											<p className="currency-sm text-gray mb-0">{parseFloat(userData.creditBalance).toFixed(2)}</p>
										</div>
									</div> */}
								</div>
							</div>
						</div>
						
						<div className="col-12 mt-5 d-block d-sm-flex justify-content-between">
							<h4 className="text-gray mb-0">{activeTab === "deposit" ? "Deposit History" : "Withdrawal History"}</h4>
							<ul className="tabs mt-3 mt-sm-0">
								<li className="nav-item">
									<button
									className={`tabs-link ${activeTab === "deposit" ? "tab-active" : ""}`}
									onClick={() => {
										setActiveTab("deposit");
										setCurrentPage(1);
									}}
									>
									Deposit
									</button>
								</li>
								<li className="nav-item">
									<button
									className={`tabs-link ${activeTab === "withdrawal" ? "tab-active" : ""}`}
									onClick={() => { 
										setActiveTab("withdrawal");
										setCurrentPage(1);
									}}
									>
									Withdrawal
									</button>
								</li>
							</ul>
						</div>

						{paginatedData && paginatedData.length > 0 ? (
							<div className="col-12 mt-3">
								<table className="table table-responsive-md table-hover table-striped table-dark">
									<thead className="thead-dark">
										<tr>
										<th className="p-3" scope="col"><p className="mb-0">Date</p></th>
										<th className="p-3" scope="col"><p className="mb-0">Method</p></th>
										<th className="p-3" scope="col"><p className="mb-0">Amount</p></th>
										<th className="p-3" scope="col"><p className="mb-0">Status</p></th>
										</tr>
									</thead>
									<tbody>
										{paginatedData.map((item, index) => (
										<tr key={index}>
											<td className="px-3 py-2">
											<p className="text-gray mb-0">{Moment(item.createdAt).format('YYYY/MM/DD HH:mm:ss')}</p>
											</td>
											<td className="px-3 py-2">
											<p className="text-gray mb-0">{item.gateway || item.method}</p>
											</td>
											<td className="px-3 py-2">
											<p className="profit-green mb-0"><strong>{parseFloat(item.req_amount || item.amount).toFixed(2)}</strong></p>
											</td>
											<td className="px-3 py-2">
											{item.status === "Success" ? (
												<p className="profit-green mb-0"><strong>{item.status}</strong></p>
											) : (
												<p className="mb-0">{item.status || "Pending"}</p>
											)}
											</td>
										</tr>
										))}
									</tbody>
								</table>
								<div className="pagination-controls mt-3 d-flex justify-content-center align-items-center">
									<button
										className="btn btn-secondary"
										onClick={() => handlePageChange(currentPage - 1)}
										disabled={currentPage === 1}
									>
										Previous
									</button>
									<span className="mx-3 text-gray">
                    Page {currentPage} of {getTotalPages(currentData)}
                </span>
                <button
                    className="btn btn-secondary"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === getTotalPages(currentData)}
                >
                    Next
                </button>
								</div>
							</div>
							) : (
							<div className="col-12 mt-3">
								<p className="text-gray mb-2">
								{activeTab === "deposit" ? "No deposit record found. Make a deposit now to start gaming!" : "No withdrawal record found."}
								</p>
								{activeTab === "deposit" && (
								<button className="btn ok-btn mt-2 mb-3" data-toggle="modal" data-target="#createDepositModal">
									<p className="mb-0">
									<i className="fa-solid fa-circle-plus mr-2"></i>
									<strong>Deposit</strong>
									</p>
								</button>
								)}
							</div>
						)}
					</>
				):(
					<></>
				)}
			</div>
		</div>
  	);

};

export default Wallet;