import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, FreeMode } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import BetsTable from "./bets-table";

// Images
import SearchIcon from "../assets/img/search-icon.png";

import HomeBanner1 from "../assets/img/banner/homebanner1.jpg";
import HomeBanner2 from "../assets/img/banner/homebanner2.jpg";
import HomeBanner3 from "../assets/img/banner/homebanner3.jpg";
import HomeBanner4 from "../assets/img/banner/homebanner4.jpg";
import HomeBanner5 from "../assets/img/banner/homebanner5.jpg";
import HomeBanner6 from "../assets/img/banner/homebanner6.png";

import game1 from "../assets/img/games/918kaya.png";
import game2 from "../assets/img/games/918kiss.png";
import game3 from "../assets/img/games/evo888.png";
import game4 from "../assets/img/games/joker.png";
import game5 from "../assets/img/games/king855.png";
import game6 from "../assets/img/games/live22.png";
import game7 from "../assets/img/games/mega.png";
import game8 from "../assets/img/games/newtown.png";
import game9 from "../assets/img/games/pussy888.png";
import game10 from "../assets/img/games/rollex.png";
import game11 from "../assets/img/games/scr888.png";
import game12 from "../assets/img/games/xe88.png";

import GPAllbet from "../assets/img/game-provider/allbet.png";
import GPEvolution from "../assets/img/game-provider/evolution.png";
import GPEvoplay from "../assets/img/game-provider/evoplay.png";
import GPKAGaming from "../assets/img/game-provider/ka-gaming.png";
import GPRedTiger from "../assets/img/game-provider/red-tiger.png";
import GPSAGamingbet from "../assets/img/game-provider/sa-gamingbet.png";
import GPEzugi from "../assets/img/game-provider/ezugi.png";
import GPSpribe from "../assets/img/game-provider/spribe.png";
import GPTFGaming from "../assets/img/game-provider/tf-gaming.png";
import GPSpadeGaming from "../assets/img/game-provider/spade-gaming.png";
import GPIAEsport from "../assets/img/game-provider/ia-esport.png";

import slider1 from "../assets/img/slider-1.png";
import slider2 from "../assets/img/slider-2.png";
import slider3 from "../assets/img/slider-3.png";
// import slider4 from "../assets/img/slider-4.png";
// import slider5 from "../assets/img/slider-5.png";
// import slider6 from "../assets/img/slider-6.png";
// import slider7 from "../assets/img/slider-7.png";
import slider8 from "../assets/img/slider-8.png";

import gameCategory1 from "../assets/img/gc-1.png";
import gameCategory2 from "../assets/img/gc-2.png";
import gameCategory3 from "../assets/img/gc-3.png";
import gameCategory4 from "../assets/img/gc-4.png";
import gameCategory5 from "../assets/img/gc-5.png";
import gameCategory6 from "../assets/img/gc-6.png";
import gameCategory7 from "../assets/img/gc-7.png";
import gameCategory8 from "../assets/img/gc-8.png";

SwiperCore.use([Autoplay, Pagination, Navigation, FreeMode]);

class Home extends Component {

  render() {
    return (
      <div className="container">
        {/* <div className="row d-block gold-border-top">
          <div className="d-flex search-bar align-items-center">
            <input
              type="text"
              className="search-input"
              // value={searchQuery}
              // onChange={(e) => setSearchQuery(e.target.value)}
            />
            <img src={SearchIcon} />
          </div>
        </div> */}
        
        {/* Home Banner */}
        <div className="row mt-4 mb-3">
          <div className="col-12">
            <Swiper
              loop
              grabCursor={true}
              slidesPerView={1}
              speed={1000}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide><img className="page-banner" src={HomeBanner6} alt="Homepage Banner"></img></SwiperSlide>
              <SwiperSlide><img className="page-banner" src={HomeBanner1} alt="Homepage Banner"></img></SwiperSlide>
              <SwiperSlide><img className="page-banner" src={HomeBanner2} alt="Homepage Banner"></img></SwiperSlide>
              <SwiperSlide><img className="page-banner" src={HomeBanner3} alt="Homepage Banner"></img></SwiperSlide>
              <SwiperSlide><img className="page-banner" src={HomeBanner4} alt="Homepage Banner"></img></SwiperSlide>
              <SwiperSlide><img className="page-banner" src={HomeBanner5} alt="Homepage Banner"></img></SwiperSlide>
            </Swiper>
          </div>
        </div>

        {/* Hot Games */}
        <div className="row mt-3 mb-3">
          <div className="col-12 my-3 d-block d-sm-flex align-items-center">
            {/* <img src={slider1} className="slider-title" /> */}
            {/* <div className="col-12 mt-3 mt-sm-0 p-0 p-sm-3 d-flex brand-gold hot-games-category">
              <h5 className="mb-0 mr-3 text-white">All</h5>
              <h5 className="mb-0 mr-3">BC Original</h5>
              <h5 className="mb-0 mr-3">Slots</h5>
              <h5 className="mb-0 mr-3">Live Casino</h5>
            </div> */}
          </div>
          {/* <div className="col-12 mt-2">
            <Swiper
              loop
              freeMode={true}
              grabCursor={true}
              slidesPerView={4}
              spaceBetween={3}
              speed={500}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                399: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
                576: {
                  slidesPerView: 6,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 8,
                  spaceBetween: 10,
                },
                1199: {
                  slidesPerView: 10,
                  spaceBetween: 10,
                },
                1400: {
                  slidesPerView: 13,
                  spaceBetween: 10,
                }
              }}
            >
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="game-box text-center">
                  <img className="hot-games-img" src={game1} alt="game-cover"></img>
                  <p className="text-white mt-1 m-0" style={{fontSize:"10px"}}><strong>PLAYER1</strong></p>
                  <p className="brand-gold mt-1" style={{fontSize:"10px"}}><strong>$560.75K</strong></p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div> */}
        </div>

        {/* Game Category */}
        <div className="row">
          <div className="col-12">
            <img src={slider2} className="slider-title" />
            {/* <h5 className="text-gray mb-0"><span className="heading-box">MOBILE GAMES</span></h5> */}
          </div>
          <div className="d-none d-sm-block">
            <div className="col-12 mt-2">
              <NavLink to="/game/category/slots">
                <img src={gameCategory1} className="col-4 pl-0 pr-2"/>
              </NavLink>
              <NavLink to="/game/category/mobile-slot-games">
                <img src={gameCategory2} className="col-4 pl-0 pr-2"/>
              </NavLink>
              <NavLink to="/game/category/casino">
                <img src={gameCategory3} className="col-4 pl-0 pr-2"/>
              </NavLink>
            </div>
            <div className="col-12 d-flex">
              <button className="btn p-0" disabled>
                {/* <NavLink to="/"> */}
                  <img src={gameCategory4} className="pl-0 pr-2" style={{width:'100%'}}/>
                {/* </NavLink> */}
              </button>
              <button className="btn p-0" disabled>
                {/* <NavLink to="/"> */}
                  <img src={gameCategory5} className="pl-0 pr-2" style={{width:'100%'}}/>
                {/* </NavLink> */}
              </button>
              <button className="btn p-0" disabled>
                {/* <NavLink to="/"> */}
                  <img src={gameCategory6} className="pl-0 pr-2" style={{width:'100%'}}/>
                {/* </NavLink> */}
              </button>
              <button className="btn p-0" disabled>
                {/* <NavLink to="/"> */}
                  <img src={gameCategory7} className="pl-0 pr-2" style={{width:'100%'}}/>
                {/* </NavLink> */}
              </button>
              <button className="btn p-0" disabled>
                {/* <NavLink to="/"> */}
                  <img src={gameCategory8} className="pl-0 pr-2" style={{width:'100%'}}/>
                {/* </NavLink> */}
              </button>
            </div>
          </div>
          <div className="d-block d-sm-none">
            <div className="col-12 mt-2">
              <button className="btn p-1 col-6">
                <NavLink to="/game/category/slots">
                  <img src={gameCategory1} className="w-100"/>
                </NavLink>
              </button>
              <button className="btn p-1 col-6">
                {/* <NavLink to="/game/category/mobile-slot-games"> */}
                <NavLink to="#">
                  <img src={gameCategory2} className="w-100"/>
                </NavLink>
              </button>
              <button className="btn p-1 col-6">
                <NavLink to="/game/category/casino">
                  <img src={gameCategory3} className="w-100"/>
                </NavLink>
              </button>
              <button className="btn p-1 col-6" disabled>
                {/* <NavLink to="/"> */}
                  <img src={gameCategory4} className="w-100"/>
                {/* </NavLink> */}
              </button>
              <button className="btn p-1 col-6" disabled>
                {/* <NavLink to="/"> */}
                  <img src={gameCategory5} className="w-100"/>
                {/* </NavLink> */}
              </button>
              <button className="btn p-1 col-6" disabled>
                {/* <NavLink to="/"> */}
                  <img src={gameCategory6} className="w-100"/>
                {/* </NavLink> */}
              </button>
              <button className="btn p-1 col-6" disabled>
                {/* <NavLink to="/"> */}
                  <img src={gameCategory7} className="w-100"/>
                {/* </NavLink> */}
              </button>
              <button className="btn p-1 col-6" disabled>
                {/* <NavLink to="/"> */}
                  <img src={gameCategory8} className="w-100"/>
                {/* </NavLink> */}
              </button>
            </div>
          </div>
            
        </div>

        {/* Mobile Games */}
        {/* <div className="row">
          <div className="col-12 my-3">
            <img src={slider3} className="slider-title" />
          </div>
          <div className="col-12 mt-2">
            <Swiper
              loop
              freeMode={true}
              grabCursor={true}
              slidesPerView={2}
              spaceBetween={10}
              speed={1000}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                576: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 15,
                },
                1200: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                }
              }}
            >
              <SwiperSlide><img className="hot-games-img" src={game1} alt="game-cover"></img></SwiperSlide>
              <SwiperSlide><img className="hot-games-img" src={game2} alt="game-cover"></img></SwiperSlide>
              <SwiperSlide><img className="hot-games-img" src={game3} alt="game-cover"></img></SwiperSlide>
              <SwiperSlide><img className="hot-games-img" src={game4} alt="game-cover"></img></SwiperSlide>
              <SwiperSlide><img className="hot-games-img" src={game5} alt="game-cover"></img></SwiperSlide>
              <SwiperSlide><img className="hot-games-img" src={game6} alt="game-cover"></img></SwiperSlide>
              <SwiperSlide><img className="hot-games-img" src={game7} alt="game-cover"></img></SwiperSlide>
              <SwiperSlide><img className="hot-games-img" src={game8} alt="game-cover"></img></SwiperSlide>
              <SwiperSlide><img className="hot-games-img" src={game9} alt="game-cover"></img></SwiperSlide>
              <SwiperSlide><img className="hot-games-img" src={game10} alt="game-cover"></img></SwiperSlide>
              <SwiperSlide><img className="hot-games-img" src={game11} alt="game-cover"></img></SwiperSlide>
              <SwiperSlide><img className="hot-games-img" src={game12} alt="game-cover"></img></SwiperSlide>
            </Swiper>
          </div>
        </div> */}

        {/* Latest Bet Table */}
        <BetsTable/>
        
        {/* Providers */}
        <div className="row">
          <div className="col-12 my-3">
          <img src={slider8} className="slider-title" />
            {/* <h5 className="text-gray mb-0"><span className="heading-box">GAME PROVIDERS</span></h5> */}
          </div>
          <div className="col-12 mt-2">
            <Swiper
              loop
              freeMode={true}
              grabCursor={true}
              slidesPerView={2}
              spaceBetween={10}
              speed={1000}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                576: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 15,
                },
                1600: {
                  slidesPerView: 6,
                  spaceBetween: 15,
                },
              }}
            >
              <SwiperSlide><img className="game-provider-img" src={GPAllbet} alt="Game Provider Logo"></img></SwiperSlide>
              <SwiperSlide><img className="game-provider-img" src={GPEvoplay} alt="Game Provider Logo"></img></SwiperSlide>
              <SwiperSlide><img className="game-provider-img" src={GPEvolution} alt="Game Provider Logo"></img></SwiperSlide>
              <SwiperSlide><img className="game-provider-img" src={GPRedTiger} alt="Game Provider Logo"></img></SwiperSlide>
              <SwiperSlide><img className="game-provider-img" src={GPKAGaming} alt="Game Provider Logo"></img></SwiperSlide>
              <SwiperSlide><img className="game-provider-img" src={GPSAGamingbet} alt="Game Provider Logo"></img></SwiperSlide>
              <SwiperSlide><img className="game-provider-img" src={GPSpadeGaming} alt="Game Provider Logo"></img></SwiperSlide>
              <SwiperSlide><img className="game-provider-img" src={GPEzugi} alt="Game Provider Logo"></img></SwiperSlide>
              <SwiperSlide><img className="game-provider-img" src={GPIAEsport} alt="Game Provider Logo"></img></SwiperSlide>
              <SwiperSlide><img className="game-provider-img" src={GPTFGaming} alt="Game Provider Logo"></img></SwiperSlide>
              <SwiperSlide><img className="game-provider-img" src={GPSpribe} alt="Game Provider Logo"></img></SwiperSlide>
            </Swiper>
          </div>
        </div>

      </div>
    );
  }
}
  
export default Home;