import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, FreeMode } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import PromoCover1 from "../assets/img/bonus1.jpg";
import PromoCover2 from "../assets/img/bonus2.jpg";
import PromoCover3 from "../assets/img/bonus3.jpg";

SwiperCore.use([Autoplay, Pagination, Navigation, FreeMode]);

const Bonus = () => {

  return (
    <div>
      <div className="container">
        <div className="row my-5">
          <div className="col-12 my-3">
            <h5 className="text-gray mb-0"><span className="heading-box">BONUS</span></h5>
          </div>
          <div className="col-12 mt-2">
            <Swiper
              loop
              freeMode={true}
              grabCursor={true}
              slidesPerView={2.5}
              spaceBetween={10}
              speed={2000}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                768: {
                  slidesPerView: 2.5,
                  spaceBetween: 15,
                },
                1600: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
              }}
            >
              <SwiperSlide><img className="hot-games-img" src={PromoCover1} alt="Promotion Thumnbail"></img></SwiperSlide>
              <SwiperSlide><img className="hot-games-img" src={PromoCover2} alt="Promotion Thumnbail"></img></SwiperSlide>
              <SwiperSlide><img className="hot-games-img" src={PromoCover3} alt="Promotion Thumnbail"></img></SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );

};

export default Bonus;