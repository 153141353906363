import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLogin } from '../hooks/useLogin';
import PhoneInput from "react-phone-input-2";
import { GoogleLogin } from "@react-oauth/google";
import "react-phone-input-2/lib/bootstrap.css";

import MainLogo from '../assets/img/logotext.png';

const LoginUsername = () => {
	const [phone, setPhone] = useState('');
	const [password, setPassword] = useState('');
	const {login, error, isLoading} = useLogin();
	
	const handleSubmit = async (e) => {
		e.preventDefault();
		await login(phone, password);
	};

	return (
		<div className="container form-page-padding">
			<div className="row py-5 my-5">
				<div className="col-12 px-2">
					<form onSubmit={handleSubmit}>
						<div className="page-container">
							<img className="form-logo mb-3" src={MainLogo}></img>
							{/* <div className="mb-3">
								<label htmlFor="username"><span className="text-gray"><strong>Username</strong></span></label>
								<input
									type="text"
									placeholder='Enter Username'
									name='username'
									className='form-control'
									onChange={(e) => setUsername(e.target.value)}
								/>
							</div> */}
							<div className="mb-3">
								<label htmlFor="username"><span className="text-gray"><strong>Username</strong></span></label>
								<input
									type="username"
									placeholder='Enter Username'
									name='username'
									className='form-control'
									onChange={(e) => setPhone(e.target.value)}
								/>
							</div>
							<div className="mb-3">
								<label htmlFor="password"><span className="text-gray"><strong>Password</strong></span></label>
								<input
									type="password"
									placeholder='Enter Password'
									name='password'
									className='form-control'
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<button disabled={isLoading} type="submit" className="btn action-button w-100 mt-3">
								<p className="mb-0"><strong>Login</strong></p>
							</button>
							<p className="text-white text-center my-2">or</p>
							<NavLink to="/login">
								<button className="btn action-button w-100">
									<p className="mb-0"><strong>Login with phone number</strong></p>
								</button>
							</NavLink>
							{error && <div className="error mt-2">{error}</div>}

							<div className="mt-3">
								<p className="text-gray mb-1">or login with social accounts</p>
								<GoogleLogin 
									onSuccess={(credentialResponese) => {
										console.log(credentialResponese)
									}} 
									onError={() => console.log("Login failed")}
									type="icon"
									shape="pill"
									width="100%"
								/>
							</div>
							<hr className="divider"></hr>
							<p className="text-gray mt-3 mb-1">Not a member yet and don't have a social account?</p>
							<NavLink to="/register">
								<button className="btn secondary-btn w-100">
									<p className="mb-0"><strong>Register with phone number</strong></p>
								</button>
							</NavLink>
							<NavLink to="/register-username">
								<button className="btn secondary-btn w-100 bg-gold mt-2">
									<p className="mb-0"><strong>Register with username</strong></p>
								</button>
							</NavLink>
						</div>
					</form>
				</div>
			</div>
		</div>
  	);

};

export default LoginUsername;