import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ scrollContainer }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (scrollContainer && scrollContainer.current) {
      scrollContainer.current.scrollTo(0, 0);
    }
  }, [pathname, scrollContainer]);

  return null;
};

export default ScrollToTop;